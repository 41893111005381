export const NUM_CLUES = 12; 

export const VALID_GATORADE_UPC = [
  "052000322514",
  "052000208054",
  "052000135152",
  "052000042122",
  "052000328684",
  "052000042313",
  "052000042276",
  "052000043174",
  "052000321807",
  "052000338775",
  "052000204094"
];

export const VALID_SNICKERS_UPC = [
  "040000424314",
  "040000001027", 
  "040000525738"
];